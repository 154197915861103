/** @jsxImportSource @emotion/react */

function Root({ household, setNewmem }) {
  return (
    <div>
      <h3 css={{ marginBottom: "5px", color: "#6c6c6c", fontWeight: "normal" }}>
        Who is the first person you want to add to the family tree?
      </h3>
      <select
        defaultValue={"prompt"}
        onChange={(e) => setNewmem(e.target.value)}
      >
        {household.map((member, i) =>
          member.inTree ? (
            <></>
          ) : (
            <option key={i} value={member.contactid}>
              {member.fullname}
            </option>
          )
        )}
        <option value="prompt">Please select a family member</option>
      </select>
    </div>
  );
}

export default Root;
