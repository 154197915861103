/** @jsxImportSource @emotion/react */
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { useDeleteConnection, useTreeData } from "./useTreeData";
import useConnections from "./getConnections";
import { useMemo } from "react";
import { ID365Contact, isNotNullOrEmpty } from "./utils";

const paramsString = document.location.search;
const searchParams = new URLSearchParams(paramsString);

interface OnDeleteProps {
  member: ID365Contact;
  delClose: () => void;
  delOpen: boolean;
  detailClose: () => void;
}
function OnDelete({ member, delOpen, delClose, detailClose }: OnDeleteProps) {
  const { contacts } = useTreeData(searchParams.getAll("householdId")[0]);
  const contactIds = useMemo(
    () =>
      contacts?.map(({ contactid }) => contactid).filter(isNotNullOrEmpty) ||
      [],
    [contacts]
  );
  const { data: connections } = useConnections(contactIds);
  const deleteMutation = useDeleteConnection(member);

  const HandleDelete = () => {
    delClose();
    detailClose();
    deleteMutation.mutate(connections || []);
  };

  return (
    <div>
      <Dialog open={delOpen} onClose={delClose}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove {member.fullname} from the family
            tree?
          </DialogContentText>
          <div
            css={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            <div
              css={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "50%",
              }}
            >
              <div css={{ cursor: "pointer" }} onClick={delClose}>
                <DialogContentText color={"#2A769D"}>Cancel</DialogContentText>
              </div>
              <div css={{ cursor: "pointer" }}>
                <DialogContentText color={"red"} onClick={HandleDelete}>
                  Remove
                </DialogContentText>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default OnDelete;
