import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useQuery } from "react-query";
import { ID365Connection } from "./utils";
import { getEnv } from "./env";

export const connectionsQueryName = "connectionData";

function useConnections(contactIds?: string[]) {
  const { instance } = useMsal();

  return useQuery(
    connectionsQueryName,
    async () => {
      const token = await instance.acquireTokenSilent({
        scopes: ["https://rcmcedev2.api.crm.dynamics.com/.default"],
      });
      const rootUrl = `${(await getEnv()).d365Root}/api/data/v9.1/connections`;
      const select = ["connectionid", "name", "description"].join(",");
      const contactsQuery = (contactIds || []).map((x) => `'${x}'`).join(",");
      const response = await axios
        .get(
          `${rootUrl}?$select=${select}&$filter=Microsoft.Dynamics.CRM.In(PropertyName='record1id',PropertyValues=[${contactsQuery}])&$expand=record1id_contact($select=fullname),record2id_contact($select=fullname),record1roleid($select=name),record2roleid($select=name)`,
          {
            headers: {
              Authorization: `Bearer ${token.accessToken}`,
            },
          }
        )
        .then((res) => res.data.value as ID365Connection[]);

      return response;
    },
    { enabled: !!contactIds?.length }
  );
}

export default useConnections;
