import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { useQuery } from "react-query";
import { getEnv } from "./env";
import { ID365Contact } from "./utils";

function useHousehold(householdId = "KING0001") {
  const { instance } = useMsal();

  return useQuery("householdData", async () => {
    const token = await instance.acquireTokenSilent({
      scopes: ["https://rcmcedev2.api.crm.dynamics.com/.default"],
    });
    const rootUrl = `${(await getEnv()).d365Root}/api/data/v9.1/contacts`;
    const select = [
      "firstname",
      "lastname",
      "nickname",
      "birthdate",
      "mobilephone",
      "telephone1",
      "telephone2",
      "emailaddress1",
      "contactid",
      "fullname",
      "middlename",
      "suffix",
      "address1_line1",
      "address1_line2",
      "address1_line3",
      "address1_city",
      "address1_stateorprovince",
      "address1_postalcode",
      "address1_country",
      "gendercode",
      "modifiedon",
      "rpm_dateofbirth",
      "entityimage_url",
      "address1_composite",
      "rcm_contacttype",
      "jobtitle",
      "rcm_secureid",
      "rcm_taxidtype",
      "rpm_headofhousehold",
      "familystatuscode",
      "rcm_rockcodbpartyid",
      "_aka_householdid_value",
      "rpm_employer",
      "_rcm_primaryadvisor_value",
      "_rcm_primaryclientassociate_value",
      "rcm_accreditedinvestor",
      "rcm_clientsegment",
      "rcm_qualifiedpurchaser",
      "rpm_networth",
    ].join(",");
    const response = await axios
      .get(
        `${rootUrl}?$select=${select}&$expand=aka_HouseholdId&$filter=statecode eq 0 and (rcm_contact_aka_rollup/any(o1:(o1/rcm_householdid eq '${householdId}')))`,
        {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      )
      .then((res) => res.data.value);
    console.log(
      "household api response",
      response,
      "HouseholdId: ",
      householdId
    );
    return response as ID365Contact[];
  });
}

export default useHousehold;
