import FamilyTree from "@balkangraph/familytree.js";
import { useCallback } from "react";
import "../treeStyle.css";

let tree;
export const useTree = () => {
  const updateTreeNodes = useCallback((updatedNodes) => {
    let newtree = updateTree(updatedNodes);
    setTimeout(() => {
      newtree.draw(FamilyTree.action.init);
    }, 100);
  }, []);

  return {
    getTree,
    getTreeContainer,
    updateTreeNodes,
  };
};

const container = document.createElement("div");
container.style.height = "100%";
const getTree = () => tree;
const getTreeContainer = () => container;
const updateTree = (updatedNodes) => {
  if (container.firstChild) {
    container.removeChild(container.firstChild);
  }
  const innerContainer = document.createElement("div");
  container.append(innerContainer);
  tree = new FamilyTree(innerContainer, {
    template: "myTemplate",
    nodes: updatedNodes,
    orientation: FamilyTree.orientation.top,

    mouseScrool: FamilyTree.action.zoom,
    miniMap: true,
    levelSeparation: 130,
    siblingSeparation: 50,
    partnerChildrenSplitSeparation: 80,
    enableSearch: false,
    tags: {
      Head: {
        template: "head",
      },
    },
    nodeBinding: {
      field_0: "fullname",
      img_0: "img",
      field_1: "rpm_dateofbirth",
      field_2: "Relationship",
      field_3: "EntField",
      memberDetails: "id",
    },
    menu: {
      pdf: { text: "Export PDF" },
      png: { text: "Export PNG" },
      svg: { text: "Export SVG" },
      csv: { text: "Export CSV" },
    },
    editForm: {
      readOnly: true,
      generateElementsFromFields: false,
      elements: [
        { type: "textbox", label: "Name", binding: "name" },
        { type: "date", label: "Birth Date", binding: "DOB" },
        [
          { type: "textbox", label: "City", binding: "city" },
          { type: "textbox", label: "State", binding: "state" },
        ],
        [
          { type: "textbox", label: "Email", binding: "email" },
          { type: "textbox", label: "Phone", binding: "phone" },
        ],
        [{ type: "textbox", label: "Entities", binding: "Entities" }],
        [{ type: "textbox", label: "Members", binding: "Members" }],
        [{ type: "textbox", label: "Association", binding: "EntField" }],
      ],
      buttons: {
        share: null,
      },
    },
    toolbar: {
      layout: false,
      zoom: true,
      fit: true,
      expandAll: false,
      fullScreen: true,
    },
  });

  tree.on("render-link", function (sender, args) {
    var cnodeData = tree.get(args.cnode.id);
    var nodeData = tree.get(args.node.id);
    if (
      cnodeData.divorced !== undefined &&
      nodeData.divorced !== undefined &&
      cnodeData.divorced.includes(args.node.id) &&
      nodeData.divorced.includes(args.cnode.id)
    ) {
      args.html = args.html.replace("path", "path stroke-dasharray='7, 4'");
    }
  });
  tree.on("click", function (sender, args) {
    // sender.editUI.show(args.node.id, false);
    // sender.editUI.show(args.node.id, true);  details mode
    return false; //to cancel the click event
  });

  return tree;
};

FamilyTree.templates.myTemplate = Object.assign({}, FamilyTree.templates.tommy);
FamilyTree.templates.myTemplate.size = [300, 80];
FamilyTree.templates.myTemplate.node = `<rect x="50" y="0" height="{h}" width="250" fill="#C6E0F2" rx="10" ry="10"></rect>
<rect x="0" y="-10" height="100" width="100" fill="#FFFFFF" rx="0" ry="0" stroke-width="1" stroke="#C6E0F2" class: shadow box-shadow: 0px 0px 9px #D8D9DA></rect>`;

FamilyTree.templates.myTemplate.img_0 =
  '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#ulaImg)" xlink:href="{val}" x="10" y="0" width="80" height="80"></image>';
FamilyTree.templates.myTemplate.nodeMenuButton = `<use x="275" y="50" ${FamilyTree.attr.control_node_menu_id}="{id}" xlink:href="#hugo_node_menu" class="node_menu"/>`;
FamilyTree.templates.myTemplate.field_0 =
  '<text style="font-size: 16px; font-weight: bold" fill="#152429" x="115" y="30" text-anchor="left">{val}</text>';
FamilyTree.templates.myTemplate.field_1 =
  '<text style="font-size: 14px;" fill="#152429" x="115" y="50" text-anchor="left">{val}</text>';
FamilyTree.templates.myTemplate.field_2 =
  '<text style="font-size: 14px;" fill="#152429" x="115" y="60" text-anchor="left">{val}</text>';

FamilyTree.templates.myTemplate.memberDetails = `<foreignobject class="node" x="260" y="5" width="200" height="100">
  <svg class="member-details" xmlns="http://www.w3.org/2000/svg" onclick="this.dispatchEvent(new CustomEvent('tree-member-click', { bubbles: true, detail: { id: this.getAttribute('data-id') }}));" data-id="{val}" width="35" height="35" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12.005 11.995v.01m0-4.01v.01m0 7.99v.01"/></svg>
</foreignobject>`;
FamilyTree.templates.myTemplate_male = FamilyTree.templates.myTemplate_female =
  Object.assign({}, FamilyTree.templates.myTemplate);

FamilyTree.templates.filtered = Object.assign(
  {},
  FamilyTree.templates.myTemplate
);
FamilyTree.templates.filtered.node = `<rect x="50" y="0" height="{h}" width="250" fill="#A0B5C3" rx="10" ry="10"></rect>
    <rect x="0" y="-10" height="100" width="100" fill="#FFFFFF" rx="0" ry="0" stroke-width="1" stroke="#A0B5C3" class: shadow box-shadow: 0px 0px 9px #D8D9DA></rect>`;
FamilyTree.templates.myTemplate.field_3 =
  '<text style="font-size: 14px;" fill="#152429" x="115" y="50" text-anchor="left">{val}</text>';
FamilyTree.templates.head = Object.assign({}, FamilyTree.templates.myTemplate);
FamilyTree.templates.head.node = `<rect x="50" y="0" height="{h}" width="250" fill="#C6E0F2" stroke="#2A769D" rx="10" ry="10"></rect>
    <rect x="0" y="-10" height="100" width="100" fill="#FFFFFF" rx="0" ry="0" stroke-width="1" stroke="#2A769D" class: shadow box-shadow: 0px 0px 9px #D8D9DA></rect>`;
