/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { createRef, useCallback, useEffect, useState } from "react";
import MemberDetailsModal from "./MemberDetailsModal";
import { useTree } from "./useTree";
import { ITreeData } from "./utils";

export const MyTree: React.FC<{ treeData?: ITreeData[] }> = ({
  treeData = [],
}) => {
  const [member, setMember] = useState(Object);
  const [openFunc, setOpenFunc] = useState(false);
  const { getTreeContainer, updateTreeNodes } = useTree();
  const treeContainerRef = createRef<HTMLDivElement>();

  const closeModal = useCallback(() => setOpenFunc(false), []);

  useEffect(() => {
    updateTreeNodes(treeData);
  }, [treeData, updateTreeNodes]);

  useEffect(() => {
    const treeContainer = getTreeContainer();
    treeContainerRef.current?.appendChild(treeContainer);
    return () => treeContainer.remove();
  }, [getTreeContainer, treeContainerRef]);

  useEffect(() => {
    const current = treeContainerRef.current;
    if (!current) {
      return;
    }

    current.style.visibility = "hidden";
    setTimeout(() => {
      current.style.visibility = "visible";
    }, 100);
  }, [treeContainerRef, treeData]);

  const nodeClick = useCallback(
    (e) => {
      const member = treeData.find((mem) => mem.id === e.detail.id);
      if (member !== undefined) {
        setMember(member);
        setOpenFunc(true);
      }
    },
    [treeData]
  );

  useEffect(() => {
    const treeContainer = treeContainerRef?.current;
    if (!treeContainer) {
      return;
    }

    treeContainer.addEventListener("tree-member-click", nodeClick);

    return () =>
      treeContainer.removeEventListener("tree-member-click", nodeClick);
  }, [nodeClick, treeContainerRef]);

  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      <div
        id="tree"
        ref={treeContainerRef}
        css={css`
          height: 100%;
          position: relative;
        `}
      >
        {openFunc ? (
          <MemberDetailsModal
            member={member}
            onClose={closeModal}
            isOpen={openFunc}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
