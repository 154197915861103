import React, { useCallback, useMemo, useState } from "react";
import { ITreeData } from "./utils";

export interface ITreeContextSetters {
  setTreeData?: (treeData?: ITreeData[]) => void;
}

export const TreeContext = React.createContext<ITreeData[]>([]);
export const TreeContextSetters = React.createContext<ITreeContextSetters>({});

export function TreeProvider({ children }) {
  const [treeData, setTreeDataState] = useState<ITreeData[]>([]);
  const setTreeData = useCallback((data: ITreeData[] = []) => {
    setTreeDataState(data);
  }, []);

  const settersContext = useMemo(
    () => ({
      setTreeData,
    }),
    [setTreeData]
  );

  return (
    <TreeContext.Provider value={treeData}>
      <TreeContextSetters.Provider value={settersContext}>
        {children}
      </TreeContextSetters.Provider>
    </TreeContext.Provider>
  );
}
