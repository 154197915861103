/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function AddDivorcedPartner({
  treeData,
  household,
  setNewmem,
  setInTreeMem,
  treeObj,
}) {
  const notInTree = household.filter((member) => !treeObj[member.contactid]);
  return (
    <div>
      <h3
        css={css`
          margin-bottom: 5px;
          color: #6c6c6c;
          font-weight: normal;
        `}
      >
        Who would you like to add as a partner?
      </h3>
      <select defaultValue={"-1"} onChange={(e) => setNewmem(e.target.value)}>
        {notInTree.map((member, i) => (
          <option key={i} value={member.contactid}>
            {member.fullname}
          </option>
        ))}
        <option value="-1">Please select the partner</option>
      </select>

      <h3
        css={css`
          margin-bottom: 5px;
          color: #6c6c6c;
          font-weight: normal;
        `}
      >
        Who is their partner?
      </h3>
      <select
        defaultValue={"-1"}
        onChange={(e) => setInTreeMem(e.target.value)}
      >
        {treeData.map((member, i) => (
          <option key={i} value={member.id}>
            {member.fullname}
          </option>
        ))}
        <option value={"-1"}>Please select their partner</option>
      </select>
    </div>
  );
}

export default AddDivorcedPartner;
