/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function ChooseRel({treeData, setNewRel}) {
  return (
    <div>
          <h3 css={css`color: #6C6C6C; font-weight: normal`}>What relationship would you like to add to the tree?</h3>
          <select
            defaultValue={"prompt"}
            onChange={(e) => setNewRel(e.target.value)}
          >
            <option value="parent">Parent/ Parents</option>
            <option value="partner">Partner</option>
            <option value="divorce">Divorced Partner</option>
            <option value="child">Child</option>
            {treeData.length === 0 ? <option value="root">Root</option> : <></>}
            <option value="prompt">Please select a relationship type</option>
          </select>
        </div>
  )
}

export default ChooseRel