export const isNotNullOrEmpty = (x: string | undefined): x is string => {
  return x != null && x?.toString()?.trim() !== "";
};

export const SimpleConnectionTypeNames = {
  partner: "partner",
  divorce: "divorce",
  child: "child",
  parent: "parent",
  root: "root",
};

export type SimpleConnectionType = keyof typeof SimpleConnectionTypeNames;

export const simpleConnectionTypeMapping: Record<SimpleConnectionType, string> =
  {
    child: "df0bf69f-333c-4e9b-86e7-4ff737bc9343",
    divorce: "741542bb-62bc-ed11-83ff-00224808d1d1",
    parent: "eda69fc6-0b5f-44fb-b584-7dfeb8a925af",
    partner: "ee375944-5415-437d-9336-7698cf665b26",
    root: "",
  };

export const simpleConnectionAltTypeMapping: Record<
  SimpleConnectionType,
  string
> = {
  child: simpleConnectionTypeMapping.parent,
  divorce: simpleConnectionTypeMapping.divorce,
  parent: simpleConnectionTypeMapping.child,
  partner: simpleConnectionTypeMapping.partner,
  root: "",
};

type ConnectionType =
  | "Spouse/Partner"
  | "Parent"
  | "Child"
  | "Divorced/ Separated Partner";

export const connectionTypeMapping: Record<ConnectionType, string> = {
  "Divorced/ Separated Partner": simpleConnectionTypeMapping.divorce,
  "Spouse/Partner": simpleConnectionTypeMapping.partner,
  Child: simpleConnectionTypeMapping.child,
  Parent: simpleConnectionTypeMapping.parent,
};

export interface ID365Connection {
  connectionid?: string;
  name?: string;
  record1id_contact: ID365ConnectionRecord;
  record2id_contact: ID365ConnectionRecord;
  record1roleid: ID365ConnectionRole;
  record2roleid: ID365ConnectionRole;
}

export interface ID365ConnectionRole {
  name?: ConnectionType;
  connectionroleid?: string;
}

export interface ID365ConnectionRecord {
  fullname?: string;
  contactid: string;
}

export interface ID365Contact {
  contactid?: string;
  inTree?: boolean;
  entityimage_url?: string;
  rpm_headofhousehold?: boolean;
  fullname?: string;
}

export type ITreeData = ID365Contact & {
  id: string;
  pids?: string[];
  mid?: string;
  fid?: string;
};

export interface ISimpleConnection {
  type?: SimpleConnectionType;
  id?: string;
  relatedId?: string;
}
