/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from "react";
import Root from "./addComponents/addRoot";
import ChooseRel from "./addComponents/chooseRel";
import AddChildToParents from "./addComponents/addChildToParents";
import AddParentsToChild from "./addComponents/addParentsToChild";
import AddPartner from "./addComponents/addPartner";
import AddDivorcedPartner from "./addComponents/addDivorcedPartner";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ID365Contact, ISimpleConnection } from "./utils";

interface AddMemberProps {
  isOpen: boolean;
  openFunc: React.Dispatch<React.SetStateAction<boolean>>;
  treeData: any[];
  onAddNode: (connections: ISimpleConnection[]) => void;
  householdData: ID365Contact[];
}

function AddMember({
  isOpen,
  openFunc,
  treeData,
  onAddNode,
  householdData,
}: AddMemberProps) {
  const [newMem, setNewmem] = useState();
  const [newRel, setNewRel] = useState("");
  const [inTreeMem, setInTreeMem] = useState<string>();
  const [parent1, setParent1] = useState<string>();
  const [parent2, setParent2] = useState<string>();
  const [isRoot, setIsRoot] = useState(false);
  useEffect(() => {
    if (treeData.length === 0) {
      setIsRoot(true);
      return;
    }

    setIsRoot(false);
  }, [treeData.length]);
  const [parentRel, setParentRel] = useState("together");
  const [numParents, setNumParents] = useState(1);

  const treeObj = {};
  treeData.forEach((mem) => {
    treeObj[mem.id] = mem;
    return mem;
  });

  const trigger = useCallback(() => {
    openFunc(false);
    const connections: ISimpleConnection[] = [];
    const mem = householdData.find((obj) => {
      return obj.contactid === newMem;
    });
    householdData.forEach((member, i) =>
      member === mem ? (member.inTree = true) : <></>
    );
    if (isRoot) {
      connections.push({ type: "root", id: mem?.contactid });
    } else if (newRel === "parent") {
      const p1 = householdData.find((obj) => {
        return obj.contactid === parent1;
      });
      const p2 = householdData.find((obj) => {
        return obj.contactid === parent2;
      });

      connections.push(
        ...[p1, p2]
          .filter((x) => x)
          .map((x) => ({
            type: "parent" as const,
            id: x?.contactid,
            relatedId: inTreeMem,
          }))
      );

      connections.push({
        type: "partner",
        id: p1?.contactid,
        relatedId: p2?.contactid,
      });
    } else if (newRel === "divorce" || newRel === "partner") {
      connections.push({
        type: newRel,
        id: mem?.contactid,
        relatedId: inTreeMem,
      });
    } else if (newRel === "child") {
      const p1 = householdData.find((obj) => {
        return obj.contactid === parent1;
      });
      const p2 = householdData.find((obj) => {
        return obj.contactid === parent2;
      });

      connections.push(
        ...[p1, p2]
          .filter((x) => x)
          .map((x) => ({
            type: "child" as const,
            id: mem?.contactid,
            relatedId: x?.contactid,
          }))
      );

      // console.log(p1,p2)
      // if (p1?.gender === "female") {
      //   if (p2 !== undefined) {
      //     onAddNode(
      //       "child",
      //       {
      //         ...mem,
      //         id: mem?.contactid,
      //         mid: p1?.contactid,
      //         fid: p2.contactid,
      //       },
      //       mutation
      //     );
      //   } else {
      //     onAddNode(
      //       "child",
      //       {
      //         ...mem,
      //         id: mem?.contactid,
      //         mid: p1?.contactid,
      //       },
      //       mutation
      //     );
      //   }
      // } else {
      //   if (p2 !== undefined) {
      //     onAddNode(
      //       "child",
      //       {
      //         ...mem,
      //         id: mem?.contactid,
      //         mid: p2.contactid,
      //         fid: p1?.contactid,
      //       },
      //       mutation
      //     );
      //   } else {
      //     onAddNode(
      //       "child",
      //       {
      //         ...mem,
      //         id: mem?.contactid,
      //         fid: p1?.contactid,
      //       },
      //       mutation
      //     );
      //   }
      // }
    }

    onAddNode(connections);
  }, [
    householdData,
    inTreeMem,
    isRoot,
    newMem,
    newRel,
    onAddNode,
    openFunc,
    parent1,
    parent2,
    parentRel,
  ]);

  const handleClose = useCallback(() => {
    openFunc(false);
  }, [openFunc]);
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle>Add Family Member</DialogTitle>

        <DialogContent>
          {treeData.length === 0 ? (
            <Root household={householdData} setNewmem={setNewmem} />
          ) : (
            <>
              <ChooseRel treeData={treeData} setNewRel={setNewRel} />
              {newRel === "child" ? (
                <AddChildToParents
                  treeData={treeData}
                  setParent1={setParent1}
                  setParent2={setParent2}
                  setChild={setNewmem}
                  household={householdData}
                  treeObj={treeObj}
                  parent1={parent1}
                />
              ) : newRel === "parent" ? (
                <AddParentsToChild
                  treeData={treeData}
                  household={householdData}
                  setParent1={setParent1}
                  setParent2={setParent2}
                  setChild={setInTreeMem}
                  setParentRel={setParentRel}
                  setNumParents={setNumParents}
                  numParents={numParents}
                  treeObj={treeObj}
                  parent1={parent1}
                />
              ) : newRel === "partner" ? (
                <AddPartner
                  treeData={treeData}
                  household={householdData}
                  setInTreeMem={setInTreeMem}
                  setNewmem={setNewmem}
                  treeObj={treeObj}
                />
              ) : newRel === "divorce" ? (
                <AddDivorcedPartner
                  treeData={treeData}
                  household={householdData}
                  setInTreeMem={setInTreeMem}
                  setNewmem={setNewmem}
                  treeObj={treeObj}
                />
              ) : (
                <></>
              )}
            </>
          )}

          <div css={{ paddingTop: "30px", paddingBottom: "30px" }}>
            <div
              css={{
                margin: "15px",
                height: "50px",
                width: "100px",
                cursor: "pointer",
                backgroundColor: "#2a769d",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "20px",
              }}
              onClick={() => trigger()}
            >
              Add
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AddMember;
