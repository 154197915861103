/** @jsxImportSource @emotion/react */
import { TreeProvider } from "./components/Context";
import Initial from "./components/Initial";
import { QueryClient, QueryClientProvider } from "react-query";
import { MsalProvider } from "@azure/msal-react";

function App({ pca }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <MsalProvider instance={pca}>
      <QueryClientProvider client={queryClient}>
        <TreeProvider>
          <Initial />
        </TreeProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
}

export default App;
