/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useState } from "react";

function AddChildToParents({
  treeData,
  setParent1,
  setParent2,
  setChild,
  household,
  treeObj,
  parent1,
}) {
  const [numParents, setNumParents] = useState(1);
  const notInTree = household.filter((member) => !treeObj[member.contactid]);

  return (
    <>
      <div
        css={css`
          width: 100%;
        `}
      >
        <h3
          css={css`
            margin-bottom: 5px;
            color: #6c6c6c;
            font-weight: normal;
          `}
        >
          Who is the child?
        </h3>
        <select defaultValue={"-1"} onChange={(e) => setChild(e.target.value)}>
          {notInTree.map((member, i) => (
            <option key={i} value={member.contactid}>
              {member.fullname}
            </option>
          ))}

          <option value="-1">Please select the child</option>
        </select>
        <h3
          css={css`
            margin-bottom: 5px;
            color: #6c6c6c;
            font-weight: normal;
          `}
        >
          How many parents does this child have?
        </h3>
        <select
          defaultValue={"1"}
          onChange={(e) => setNumParents(Number(e.target.value))}
        >
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
        {numParents === 1 ? (
          <>
            <h3
              css={css`
                margin-bottom: 5px;
                color: #6c6c6c;
                font-weight: normal;
              `}
            >
              Who is the parent?
            </h3>
            <select
              defaultValue={"-1"}
              onChange={(e) => setParent1(e.target.value)}
            >
              {treeData.map((member, i) => (
                <option key={i} value={member.id}>
                  {member.fullname}
                </option>
              ))}
              <option value={"-1"}>Please select their parent</option>
            </select>
          </>
        ) : (
          <>
            <h3
              css={css`
                margin-bottom: 5px;
                color: #6c6c6c;
                font-weight: normal;
              `}
            >
              Who is the first parent?
            </h3>
            <select
              defaultValue={"prompt"}
              onChange={(e) => setParent1(e.target.value)}
            >
              {treeData.map((member, i) =>
                member.pids.length > 0 ? (
                  <option key={i} value={member.id}>
                    {member.fullname}
                  </option>
                ) : (
                  <></>
                )
              )}
              <option value="-1">Please select one of their parents</option>
            </select>

            <h3
              css={css`
                margin-bottom: 5px;
                color: #6c6c6c;
                font-weight: normal;
              `}
            >
              Who is the second parent?
            </h3>
            <select
              defaultValue={"-1"}
              onChange={(e) => setParent2(e.target.value)}
            >
              {treeData.map((member, i) =>
                member.pids.length > 0 && member.contactid !== parent1 ? (
                  <option key={i} value={member.id}>
                    {member.fullname}
                  </option>
                ) : (
                  <></>
                )
              )}
              <option value="-1">Please select one of their parents</option>
            </select>
          </>
        )}
      </div>
    </>
  );
}

export default AddChildToParents;
