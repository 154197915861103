/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState, useMemo } from "react";
import { Dialog } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import { useMutation } from "react-query";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import OnDelete from "./OnDelete";
import trashCan from "../assets/trashCan.svg";
import location from "../assets/Location.svg";
import phone from "../assets/PhoneIcon.svg";
import message from "../assets/Message.svg";

interface MemberModalProps {
  member: any;
  onClose: () => void;
  isOpen: boolean;
}

const containsOnlyDigits = (str: string) => /^\d+$/.test(str);

function MemberDetailsModal({ member, onClose, isOpen }: MemberModalProps) {
  const ssn = useMemo(() => {
    if (
      !member?.rcm_secureid ||
      !containsOnlyDigits(member?.rcm_secureid) ||
      member?.rcm_secureid?.length !== 9
    ) {
      return member?.rcm_secureid;
    } else {
      return `${member?.rcm_secureid.slice(0, 3)}-${member?.rcm_secureid.slice(
        3,
        5
      )}-${member?.rcm_secureid.slice(5, 9)}`;
    }
  }, [member?.rcm_secureid]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);
  console.log(member);
  const { instance } = useMsal();
  const [selectedImage, setImage] = useState<File | null>(null);
  const { mutate } = useMutation({
    mutationFn: async (newImg: { entityimage: string }) => {
      const token = await instance.acquireTokenSilent({
        scopes: ["https://rcmcedev2.api.crm.dynamics.com/.default"],
      });
      return axios.patch(
        `https://rcmceqa.crm.dynamics.com/api/data/v9.0/contacts(${member.contactid})`,
        newImg,
        {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      );
    },
  });

  const encodeImageFileAsURL = useCallback(
    (element) => {
      var file = element;
      var reader = new FileReader();
      reader.onloadend = function () {
        let result = reader.result;
        let commaIndex = -1;
        if (result !== undefined && typeof result === "string") {
          commaIndex = result.indexOf(",");
          result = result.substring(commaIndex + 1);
        }
        mutate({ entityimage: result as string });
      };
      reader.readAsDataURL(file);
    },
    [mutate]
  );

  useEffect(() => {
    if (selectedImage !== null) {
      // TODO - how do we update the image in the tree without refetching all the members?
      member.img = URL.createObjectURL(selectedImage);
      encodeImageFileAsURL(selectedImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encodeImageFileAsURL, selectedImage]);

  const [delOpen, setDelOpen] = useState(false);

  const handleDelOpen = () => {
    console.log("Clicking trash");
    setDelOpen(true);

    // handleClose();
  };
  const handleDelClose = useCallback(() => setDelOpen(false), []);

  function maritalStatus(code) {
    if (code === 1) {
      return "Single";
    } else if (code === 2) {
      return "Married";
    } else if (code === 3) {
      return "Divorced";
    } else if (code === 4) {
      return "Widowed";
    }
  }

  function getGender(code) {
    if (code === 1) {
      return "Male";
    } else {
      return "Female";
    }
  }

  function getContactType(code) {
    if (code === 798960001) {
      return "Attorney";
    }
    if (code === 100000004) {
      return "Centers of Influence (COI)";
    }
    if (code === 100000000) {
      return "Client";
    }
    if (code === 798960002) {
      return "Client Assistant";
    }
    if (code === 100000001) {
      return "Client Contact";
    }
    if (code === 964110000) {
      return "Consultant";
    }
    if (code === 798960000) {
      return "CPA";
    }
    if (code === 964110002) {
      return "Financial Advisor";
    }
    if (code === 798960003) {
      return "Former Client";
    }
    if (code === 964110003) {
      return "Friends/Family";
    }
    if (code === 100000003) {
      return "Lead";
    }
    if (code === 100000005) {
      return "Mortgage Broker";
    }
    if (code === 927390000) {
      return "Prospect";
    }
    if (code === 100000002) {
      return "Third Party";
    }
  }

  function getTaxId(code) {
    if (code === 798960001) {
      return "SSN";
    } else if (code === 798960000) {
      return "Not Specified";
    } else if (code === 798960002) {
      return "TIN";
    }
  }

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle css={{ backgroundColor: "#B0D6F1" }}>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt="member"
                width={"45px"}
                height={"45px"}
                src={
                  selectedImage !== null
                    ? URL.createObjectURL(selectedImage)
                    : member.img
                }
              />
              <div
                css={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "10px",
                }}
              >
                <div css={{ fontSize: "18px" }}>{member.fullname}</div>
                <div css={{ fontSize: "12px" }}>
                  {" "}
                  {member.rpm_dateofbirth ? (
                    <DialogContentText>
                      DOB: {member.rpm_dateofbirth}
                    </DialogContentText>
                  ) : (
                    <DialogContentText>DOB: -</DialogContentText>
                  )}
                </div>
              </div>
            </div>

            <div css={{ cursor: "pointer" }} onClick={() => handleDelOpen()}>
              <img
                src={trashCan}
                css={{
                  width: 25,
                  height: 25,
                }}
                alt="Remove Family Member"
              />
            </div>
          </div>
        </DialogTitle>
        <DialogContent css={{ backgroundColor: "#B0D6F1" }}>
          {/* <button onClick={() => setImage(null)}>Remove</button> */}

          <DialogContentText css={{ display: "flex", alignItems: "center" }}>
            <img
              alt="Address"
              width={"15px"}
              height={"15px"}
              src={location}
              css={{ paddingRight: "10px" }}
            />
            {member.address1_line1 ? (
              <div css={{ display: "flex" }}>
                {member.address1_line1}
                <br />
                {member?.address1_city}, {member?.address1_stateorprovince},{" "}
                {member?.address1_postalcode}
                <br />
                {member?.address1_country}
              </div>
            ) : (
              <div css={{ display: "flex" }}>
                -
                <br />
                -
                <br />-
              </div>
            )}
          </DialogContentText>

          <div>
            <DialogContentText css={{ display: "flex", alignItems: "start" }}>
              <img
                alt="Phone"
                width={"15px"}
                height={"15px"}
                src={phone}
                css={{ paddingTop: "5px", paddingRight: "10px" }}
              />
              {member.mobilephone || member.telephone1 || member.telephone2 ? (
                <div css={{ display: "flex", flexDirection: "column" }}>
                  {member.mobilephone}
                  {" (Mobile)"}
                  <br />
                  {member.telephone1 || member.mobilephone}
                  {" (Business)"}
                  <br />
                  {member.telephone2 || member.mobilephone}
                  {" (Home)"}
                </div>
              ) : (
                <div css={{ display: "flex", flexDirection: "column" }}>
                  {member.mobilephone || "-"}
                  {" (Mobile)"}
                  <br />
                  {member.telephone1 || member.mobilephone || "-"}
                  {" (Business)"}
                  <br />
                  {member.telephone2 || member.mobilephone || "-"}
                  {" (Home)"}
                </div>
              )}
            </DialogContentText>
          </div>

          <DialogContentText css={{ display: "flex", alignItems: "start" }}>
            <img
              alt="Phone"
              width={"15px"}
              height={"15px"}
              src={message}
              css={{ paddingTop: "5px", paddingRight: "10px" }}
            />
            {member.emailaddress1 ? member.emailaddress1 : <div>-</div>}
          </DialogContentText>
          <div
            css={{
              display: "grid",
              paddingTop: "45px",
              gridTemplateColumns: "repeat(2, 1fr)",
            }}
          >
            <DialogContentText
              css={{ gridColumn: "1/2", paddingRight: "20px" }}
            >
              <div css={{ display: "flex" }}>
                Contact Type:{" "}
                {member.rcm_contacttype ? (
                  getContactType(member.rcm_contacttype)
                ) : (
                  <div css={{ paddingLeft: "8px" }}>-</div>
                )}
              </div>
            </DialogContentText>
            <DialogContentText css={{ gridColumn: "2" }}>
              <div css={{ display: "flex" }}>
                Tax Id Type:{" "}
                {member.rcm_taxidtype ? (
                  getTaxId(member.rcm_taxidtype)
                ) : (
                  <div css={{ paddingLeft: "8px" }}>-</div>
                )}
              </div>
            </DialogContentText>
            <DialogContentText
              css={{ gridColumn: "1/2", paddingRight: "20px" }}
            >
              <div css={{ display: "flex" }}>
                Job Title:{" "}
                {member.jobtitle ? (
                  member.jobtitle
                ) : (
                  <div css={{ paddingLeft: "8px" }}>-</div>
                )}
              </div>
            </DialogContentText>
            <DialogContentText css={{ gridColumn: "2" }}>
              <div css={{ display: "flex" }}>
                SSN: {ssn ? ssn : <div css={{ paddingLeft: "8px" }}>-</div>}
              </div>
            </DialogContentText>

            <DialogContentText css={{ gridColumn: "1/2" }}>
              <div css={{ display: "flex" }}>
                Gender:{" "}
                {member.gendercode ? (
                  getGender(member.gendercode)
                ) : (
                  <div css={{ paddingLeft: "8px" }}>-</div>
                )}
              </div>
            </DialogContentText>
            <DialogContentText css={{ gridColumn: "2" }}>
              <div css={{ display: "flex" }}>
                Marital Status:{" "}
                {member.familystatuscode ? (
                  maritalStatus(member.familystatuscode)
                ) : (
                  <div css={{ paddingLeft: "8px" }}>-</div>
                )}
              </div>
            </DialogContentText>
            <DialogContentText
              css={{ gridColumn: "1/2", paddingBottom: "20px" }}
            >
              <div css={{ display: "flex" }}>
                Nickname:{" "}
                {member.nickname ? (
                  member.nickname
                ) : (
                  <div css={{ paddingLeft: "8px" }}>-</div>
                )}
              </div>
            </DialogContentText>
          </div>

          <input
            type="file"
            name="inputtedImage"
            onChange={(e) => {
              if (!e.target.files || e.target.files.length === 0) return;
              setImage(e.target.files[0]);
            }}
            accept="image/jpeg, image/png, image/jpg"
          />
        </DialogContent>
      </Dialog>
      <div>
        {delOpen ? (
          <OnDelete
            member={member}
            delOpen={delOpen}
            delClose={handleDelClose}
            detailClose={onClose}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default MemberDetailsModal;
