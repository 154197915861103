/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

function AddParentsToChild({
  treeData,
  household,
  setParent1,
  setParent2,
  setChild,
  setParentRel,
  setNumParents,
  numParents,
  treeObj,
  parent1,
}) {
  const notInTree = household.filter((member) => !treeObj[member.contactid]);
  const needParents = treeData.filter(
    (member) => member.mid === undefined && member.fid === undefined
  );

  return (
    <div>
      <h3
        css={css`
          color: #6c6c6c;
          font-weight: normal;
        `}
      >
        How many parents would you like to add?
      </h3>
      <select
        defaultValue={"1"}
        onChange={(e) => setNumParents(Number(e.target.value))}
      >
        <option value="1">1</option>
        <option value="2">2</option>
      </select>
      {numParents === 1 ? (
        <>
          <h3
            css={css`
              color: #6c6c6c;
              font-weight: normal;
            `}
          >
            Who is the parent?
          </h3>
          <select
            defaultValue={"-1"}
            onChange={(e) => setParent1(e.target.value)}
          >
            {notInTree.map((member, i) => (
              <option key={i} value={member.contactid}>
                {member.fullname}
              </option>
            ))}
            <option value="-1">Please select a parent</option>
          </select>
        </>
      ) : (
        <>
          <h3
            css={css`
              color: #6c6c6c;
              font-weight: normal;
            `}
          >
            Who is parent 1?
          </h3>
          <select
            defaultValue={"prompt"}
            onChange={(e) => setParent1(e.target.value)}
          >
            {notInTree.map((member, i) => (
              <option key={i} value={member.contactid}>
                {member.fullname}
              </option>
            ))}
            <option value="prompt">Please select a parent</option>
          </select>
          <h3
            css={css`
              color: #6c6c6c;
              font-weight: normal;
            `}
          >
            Who is parent 2?
          </h3>
          <select
            defaultValue={"prompt"}
            onChange={(e) => setParent2(e.target.value)}
          >
            {notInTree.map((member, i) =>
              member.contactid !== parent1 ? (
                <option key={i} value={member.contactid}>
                  {member.fullname}
                </option>
              ) : (
                <></>
              )
            )}
            <option value="prompt">Please select a parent</option>
          </select>
          <h3
            css={css`
              margin-bottom: 5px;
              color: #6c6c6c;
              font-weight: normal;
            `}
          >
            Are they together or separated?
          </h3>
          <select
            defaultValue={"together"}
            onChange={(e) => setParentRel(e.target.value)}
          >
            <option value="together">Together</option>
            <option value="separated">Separated</option>
          </select>
        </>
      )}

      <h3
        css={css`
          margin-bottom: 5px;
          color: #6c6c6c;
          font-weight: normal;
        `}
      >
        Who is the child?
      </h3>
      <select defaultValue={"-1"} onChange={(e) => setChild(e.target.value)}>
        {needParents.map((member, i) => (
          <option key={i} value={member.id}>
            {member.fullname}
          </option>
        ))}
        <option value={"-1"}>Please select their child</option>
      </select>
    </div>
  );
}

export default AddParentsToChild;
