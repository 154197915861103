/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useCallback, useContext, useEffect } from "react";
import { MyTree } from "./mytree";
// import Tabs from "./Tabs";
import AddMemBtn from "./AddMemBtn";
import SignInSignOutButton from "./msal/ui-components/SignInSignOutButton";
import { useTreeData, useUpdateConnections } from "./useTreeData";
import { TreeContext, TreeContextSetters } from "./Context";
import { ISimpleConnection } from "./utils";
import entities from "./Entities.json";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./msal/authConfig";

//?householdId=KING0001
const paramsString = document.location.search;
const searchParams = new URLSearchParams(paramsString);

function Initial() {
  const { treeData, isLoading, error, contacts, isAuthenticated } = useTreeData(
    searchParams.getAll("householdId")[0]
  );
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRequest);
  }

  const { updateConnections } = useUpdateConnections();
  const currTab = "Family Tree";
  const onAddNode = useCallback(
    (connections: ISimpleConnection[]) => {
      console.log("connections", connections);
      updateConnections.mutate(connections);
    },
    [updateConnections]
  );
  const contextTreeData = useContext(TreeContext);
  console.log(contextTreeData);
  const { setTreeData: setContextTreeData } = useContext(TreeContextSetters);

  useEffect(() => {
    if (currTab === "Family Tree") {
      setContextTreeData?.(treeData);
      return;
    }

    const entity = entities.find((x) => x.name === currTab);
    if (entity) {
      const newTreeData = [
        {
          id: entity?.accountNumber,
          fullname: entity?.name,
          img: "https://static.vecteezy.com/system/resources/previews/000/554/920/original/royal-crown-vector-icon.jpg",
          Members: entity?.Trustees.map((x) => x.fullname),
          Details: entity?.Description,
        },
        ...(entity?.Trustees || []),
      ];
      setContextTreeData?.(newTreeData as any);
    }
  }, [currTab, setContextTreeData, treeData, isAuthenticated]);

  function getMessage() {
    if (!isAuthenticated) {
      return "Sign into your Rockefeller account to view your family tree";
    } else if (isLoading) {
      return "Loading...";
    } else if (error) {
      return "An error has occurred";
    }
  }
  return (
    <div
      css={css`
        height: 100vh;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={{
          backgroundColor: "#324b53",
          color: "white",
          display: "flex",
          flexDirection: "row",
          width: "100vw",
          justifyContent: "end",
        }}
      >
        <div
          css={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            fontSize: "30px",
            fontWeight: "normal",
          }}
        >
          <div css={{ margin: "10px" }}>Relationship View</div>
          <div
            css={{
              display: "none",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SignInSignOutButton />
          </div>
        </div>
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: row-reverse;
          max-height: 76px;
        `}
      >
        {/* <Tabs currTab={currTab} setTab={setTab} /> */}
        {currTab === "Family Tree" ? (
          <AddMemBtn
            treeData={treeData as any}
            onAddNode={onAddNode}
            household={contacts || []}
          />
        ) : (
          <></>
        )}
      </div>
      <div css={{ fontSize: "30px", textAlign: "center" }}>{getMessage()}</div>

      <div
        css={css`
          ${!isLoading && !error && treeData.length === 0
            ? `display: flex; justify-content: center; text-align: center; align-items: center; height: 100%`
            : `display: none`};
        `}
      >
        <h1
          css={css`
            width: 70%;
          `}
        >
          Click the plus button to begin adding family members to the
          relationship tree.
        </h1>
      </div>
      <div
        css={css`
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          ${!isLoading && !error && isAuthenticated && treeData.length !== 0
            ? ``
            : `display: none;`};
        `}
      >
        <MyTree treeData={contextTreeData} />
      </div>
    </div>
  );
}

export default Initial;
