import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios from "axios";
import { groupBy, keyBy, uniq } from "lodash";
import { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import useConnections, { connectionsQueryName } from "./getConnections";
import useHousehold from "./getHousehold";
// import Avatar from "../assets/Avatar.svg";
import {
  ID365Connection,
  ID365Contact,
  ISimpleConnection,
  isNotNullOrEmpty,
  ITreeData,
  simpleConnectionAltTypeMapping,
  simpleConnectionTypeMapping,
} from "./utils";
import { AuthenticationResult } from "@azure/msal-browser";

export const useTreeData = (householdId?: string) => {
  const isAuthenticated = useIsAuthenticated();
  const {
    data: contacts,
    isLoading: isContactsLoading,
    error: contactsError,
  } = useHousehold(householdId);
  const contactIds = useMemo(
    () =>
      contacts?.map(({ contactid }) => contactid).filter(isNotNullOrEmpty) ||
      [],
    [contacts]
  );
  const {
    data: connections,
    isLoading: isConnectionsLoading,
    error: connectionsError,
  } = useConnections(contactIds);

  const treeData = useMemo(
    () => constructTreeFromRawData(contacts || [], connections),
    [connections, contacts]
  );

  return {
    contacts,
    connections,
    contactIds,
    treeData,
    isLoading: isContactsLoading || isConnectionsLoading,
    error: contactsError || connectionsError,
    isAuthenticated,
  };
};

const constructTreeFromRawData = (
  contacts: ID365Contact[],
  connections?: ID365Connection[]
): ITreeData[] => {
  if (!contacts?.length || !connections?.length) {
    return [];
  }
  const contactLookup = keyBy(contacts, ({ contactid }) => contactid || "");
  const contactsInTree = uniq(
    connections.flatMap((x) => [
      x.record1id_contact?.contactid,
      x.record2id_contact?.contactid,
    ])
  ).filter((x) => x);

  return contactsInTree
    .map((contactid) => {
      const contact = contactLookup[contactid];
      if (!contact) {
        return null;
      }
      const contactConnections = connections.filter(
        (x) => x.record1id_contact.contactid === contactid && x.record1roleid
      );
      const connectionsGroupedByType = groupBy(
        contactConnections,
        (x) => x.record1roleid.connectionroleid
      );

      Object.keys(connectionsGroupedByType).forEach((x) => {
        if (
          x !== simpleConnectionTypeMapping.partner &&
          x !== simpleConnectionTypeMapping.divorce &&
          x !== simpleConnectionTypeMapping.parent &&
          x !== simpleConnectionTypeMapping.child
        ) {
          delete connectionsGroupedByType[x];
        }
      });
      const pids =
        connectionsGroupedByType[simpleConnectionTypeMapping.partner]?.map(
          (x) => x.record2id_contact.contactid
        ) || [];

      const divorced =
        connectionsGroupedByType[simpleConnectionTypeMapping.divorce]?.map(
          (x) => x.record2id_contact.contactid
        ) || [];
      pids.push(...divorced);
      const parents =
        connectionsGroupedByType[simpleConnectionTypeMapping.parent];
      const [mid, fid] =
        parents?.map((x) => x.record2id_contact.contactid) || [];

      return {
        ...contact,
        id: contact.contactid as string,
        tags: contact.rpm_headofhousehold ? ["Head"] : [],
        pids,
        divorced,
        mid,
        fid,
        img: contact.entityimage_url
          ? "https://rcmceqa.crm.dynamics.com" + contact.entityimage_url
          : "https://www.bwreed.co.uk/wp-content/uploads/2018/01/placeholder-profile-image.png",
      };
    })
    .filter(isNotNullOrUndefined);
};
function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input != null;
}

export const useDeleteConnection = (member: ID365Contact) => {
  const { instance } = useMsal();
  const queryClient = useQueryClient();
  const getToken = async () => {
    const token = await instance.acquireTokenSilent({
      scopes: ["https://rcmcedev2.api.crm.dynamics.com/.default"],
    });
    return token;
  };

  const deleteConnections = (
    connections: ID365Connection[],
    token: AuthenticationResult
  ) => {
    const uniqIds = uniq(
      connections
        ?.filter(
          (connection) =>
            // only need to remove one side of the relationship since the relationships are reciprocal
            connection.record1id_contact.contactid === member.contactid
        )
        .map((x) => x.connectionid)
    );

    console.log("ids", uniqIds);
    return uniqIds.map((id) =>
      axios.delete(
        `https://rcmceqa.crm.dynamics.com/api/data/v9.0/connections(${id})`,
        {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        }
      )
    );
  };

  const deleteMutation = useMutation(
    async (connections: ID365Connection[]) => {
      console.log("delete", connections);
      const token = await getToken();
      return Promise.all(deleteConnections(connections, token));
    },
    {
      onMutate: async (connections) => {
        await queryClient.cancelQueries({ queryKey: [connectionsQueryName] });
        const previousTodos = queryClient.getQueryData([connectionsQueryName]);
        const deletedConnections = uniq(
          connections?.filter(
            (connection) =>
              connection.record1id_contact.contactid === member.contactid ||
              connection.record2id_contact.contactid === member.contactid
          )
        );

        queryClient.setQueryData(
          [connectionsQueryName],
          (old?: ID365Connection[]) => [
            ...(old?.filter((x) => !deletedConnections.includes(x)) || []),
          ]
        );
        return { previousTodos };
      },
    }
  );

  return deleteMutation;
};

interface IConnectionUpdatePayload {
  "record1roleid@odata.bind": string;
  "record2roleid@odata.bind": string;
  "record1id_contact@odata.bind": string;
  "record2id_contact@odata.bind": string;
  "ownerid@odata.bind": string;
}

export const useUpdateConnections = () => {
  const { instance } = useMsal();
  const queryClient = useQueryClient();
  const getToken = async () => {
    const token = await instance.acquireTokenSilent({
      scopes: ["https://rcmcedev2.api.crm.dynamics.com/.default"],
    });
    return token;
  };
  const updateConnections = useMutation(
    async (simpleConnections: ISimpleConnection[]) => {
      const token = await getToken();
      const requests = simpleConnections
        .map(
          (x): IConnectionUpdatePayload => ({
            "record1id_contact@odata.bind": `/connectionroles(${x.relatedId})`,
            "record2id_contact@odata.bind": `/connectionroles(${x.id})`,
            "record1roleid@odata.bind": `/connectionroles(${
              simpleConnectionTypeMapping[x.type as string]
            })`,
            "record2roleid@odata.bind": `/connectionroles(${
              simpleConnectionAltTypeMapping[x.type as string]
            })`,
            "ownerid@odata.bind":
              "/systemusers(6ce5d065-d25a-ed11-9561-00224808db07)",
          })
        )
        .map((x) => {
          return axios.post(
            "https://rcmceqa.crm.dynamics.com/api/data/v9.1/connections",
            x,
            {
              headers: {
                Authorization: `Bearer ${token.accessToken}`,
              },
            }
          );
        });

      await Promise.all(requests);
    },
    {
      onMutate: async (newSimpleConnections) => {
        await queryClient.cancelQueries({ queryKey: [connectionsQueryName] });
        const previousTodos = queryClient.getQueryData([connectionsQueryName]);

        const newConnections = newSimpleConnections.flatMap(
          (x): ID365Connection[] => [
            {
              record1id_contact: {
                contactid: x.relatedId || "",
              },
              record2id_contact: {
                contactid: x.id || "",
              },
              record1roleid: {
                connectionroleid: simpleConnectionTypeMapping[x.type as string],
              },
              record2roleid: {
                connectionroleid:
                  simpleConnectionAltTypeMapping[x.type as string],
              },
            },
            {
              record1id_contact: {
                contactid: x.id || "",
              },
              record2id_contact: {
                contactid: x.relatedId || "",
              },
              record1roleid: {
                connectionroleid:
                  simpleConnectionAltTypeMapping[x.type as string],
              },
              record2roleid: {
                connectionroleid: simpleConnectionTypeMapping[x.type as string],
              },
            },
          ]
        );

        queryClient.setQueryData(
          [connectionsQueryName],
          (old?: ID365Connection[]) => [...(old || []), ...newConnections]
        );
        return { previousTodos };
      },
    }
  );

  return { updateConnections };
};
