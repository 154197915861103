import axios from "axios";

export const getEnv = () => {
  const fileName = "environment.json";

  return axios

    .get<any>(`/${fileName}`)

    .then((x) => x.data);
};
